import { Avatar, Card, CardHeader, Typography } from "@material-ui/core";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import React from "react";
import { globalColors } from "../../../assets/css/Styles";
import i18n from "../../../locales/i18n";
import withHover from "../../withHover";

const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

/**
 * Card que aparece en la lista de Plantas y de Clientes
 * @Component
 * @param {Object} props
 * @param {string} props.title //Titulo Principal de la Card
 * @param {string} props.subtitle // Informacin secundaria de la cad
 * @param {Array} props.contents // Lineas de datos
 * @param {Object} props.onClick //evento click
 * @param {boolean} props.isDanfossOwned // Si es propiedad de Danfoss
 * @param {string} props.type // Tipo de calrd [Cliente, Planta]
 * @param {Object} [props.style] // Fondo y estilo de la card
 * @param {string} [props.className] // Fondo y estilo de la card
 *
 */
function CardPlantClient(props) {
  const { title, subtitle, contents, onClick, type, style, className } = props;
  const abbreviatonLetters = title.split(" ");
  const abbreviaton =
    abbreviatonLetters.length > 1
      ? (abbreviatonLetters[0][0] + abbreviatonLetters[1][0]).toUpperCase()
      : title.substr(0, 2).toUpperCase();
  return (
    <CardWithHover className={className} onClick={onClick} data-cy="CardInfo">
      <CardHeader
        classes={{ content: "overflow-hidden" }}
        titleTypographyProps={{
          noWrap: true,
          variant: "body2",
        }}
        subheaderTypographyProps={{ noWrap: true, variant: "body2" }}
        title={<div className={style.plantCardTitle}>{title}</div>}
        subheader={<div className={style.plantCardSubtitle}>{subtitle}</div>}
        avatar={
          <Avatar aria-label="avatar" className={style.plantCardAvatar}>
            {abbreviaton}
          </Avatar>
        }
        action={
          props.isDanfossOwned ? (
            <BookmarkIcon style={{ color: globalColors.activeColor }} />
          ) : undefined
        }
      />
      <div className={style.plantCardContent}>
        {contents.map((value, index) => (
          <Typography
            key={index}
            style={{ fontFamily: "MyriadPro" }}
            variant="body2"
            display="block"
            noWrap
          >
            <span style={{ fontWeight: "bold" }}>
              {i18n.t(type + "." + value.key)}
            </span>{" "}
            : {value.value}
          </Typography>
        ))}
      </div>
    </CardWithHover>
  );
}

export default CardPlantClient;
