import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import SearchField from "../../molecule/SearchField/SearchField";
import SelectFrequencyConvertersSearchEngineFilter from "../../molecule/Select_FrequencyConvertersSearchEngineFilter/Select_FrequencyConvertersSearchEngineFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import EquipoCard from "../../../components/organism/EquipoCard/EquipoCard";
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import {
  AddCircleOutline,
  MenuOpen,
  MoreVert,
  Build,
  Reorder,
  ViewCarousel,
  FindReplace,
  FilterList,
  Refresh,
} from "@material-ui/icons";
import i18n from "../../../locales/i18n";
import Equipo from "../../../models/equipo";
import DialogRevisionEquipos from "../DialogRevisionEquipos/DialogRevisionEquipos";
import { BrandAPI, EquiposAPI, PlantasAPI } from "../../../services/API";
import { SnackbarSuccess, SnackbarError } from "../../../helpers/Common";
import AlertDialog from "../../../components/AlertDialog";
import AppContext from "../../../contexts/AppContext";
import {
  DetailTabs_Style,
  CardList_Style,
  Common_Style,
  globalColors,
} from "../../../assets/css/Styles";
import DriveListView from "../DriveListView/DriveListView";
import DriveListHeader from "../DriveListView/DriveListHeader";
import DialogEmergencyStock from "../DialogDriveActions/DialogEmergencyStock";
import DialogRetrofitProposal from "../DialogDriveActions/DialogRetrofitProposal";
import AuthService from "../../../services/AuthService";
import DisableFilter from "../../atoms/disableFilter";
import ScrollDialog from "../../ScrollDialog";

export default function FrequencyConvertersSearchEngine(props) {
  const {
    readOnly,
    isPremium,
    PlantaId,
    ZonaId,
    history,
    goBack,
    setErrors,
    changeZone,
    setChangeZone,
    fromTreeView,
    nDrivers,
  } = props;
  const confirmNextDriverDialog = useRef(null);
  const confirmPastDriverDialog = useRef(null);
  const [loadingEquipos, setLoadingEquipos] = useState(false);
  const [equipos, setEquipos] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [filtersCleaned, setFiltersCleaned] = useState([]);
  const [brands, setBrands] = useState([]);
  const [equipo, setEquipo] = useState(new Equipo());
  const [hasMoreEquipos, setHasMoreEquipos] = useState(false);
  const [changed, setChanged] = useState(false);
  const [activeFilter, setActiveFilter] = useState(0);
  const [index, setIndex] = useState(0);
  const [openRevision, setOpenRevision] = useState(false);
  const [openESDialog, setOpenESDialog] = useState(false);
  const [openRetroDialog, setOpenRetroDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFirstInput, setIsFirstInput] = useState(false);
  const [drivesChecked, setDrivesChecked] = useState([]);
  const [checkDrivesInWarehouse, setCheckDrivesInWarehouse] = useState([]);
  const [checkDrivesInactive, setCheckDrivesInactive] = useState([]);
  const [checkAllDrive, setcheckAllDrive] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loadingDrives, setLoadingDrives] = useState(false);
  const [hoverFilterButton, setHoverFilterButton] = useState(false);
  const [sortValue, setSortValue] = useState("IdAsc");
  const [dialogRefreshOpen, setDialogRefreshOpen] = useState(false);
  const [dialogRefreshText, setDialogRefreshText] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [filterChanged, setFilterChanged] = useState(false);
  const [filtersObtained, setFiltersObtained] = useState(false);
  //Permisio para todo lo de acciones
  const stockPermission =
    AuthService.hasRoleAdmin() ||
    AuthService.hasRoleDSC() ||
    AuthService.hasRolePartner();
  const [viewMode, setViewMode] = useState("List");
  const [driveFilters, setDriveFilters] = useState([]);
  const scrollableDivRef = useRef(null);
  const headerRef = useRef(null);
  //Referencia para el menu de acciones
  const [anchorEl, setAnchorEl] = useState(null);
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();
  const cardStyles = CardList_Style();

  //GetEquipos
  const getInfiniteDrives = useCallback(
    async (
      PlantaId,
      ZonaId,
      selectorFilter,
      actualLength,
      searchQuery,
      isNewQuery,
      driveFilters
    ) => {
      setLoadingEquipos(true);
      setLoadingDrives(true);
      if (ZonaId === null || ZonaId === undefined) {
        ZonaId = 0;
      }
      if (!searchQuery) {
        searchQuery = "";
      }
      EquiposAPI.getInfiniteDrives(
        PlantaId,
        ZonaId,
        selectorFilter,
        actualLength,
        searchQuery,
        driveFilters
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          //Si es una nueva busqueda se reemplaza la lista de equipos
          if (isNewQuery) {
            setEquipos(data.equipos);
          } else {
            setEquipos((old) => old.concat(data.equipos));
          }
          //Si no hay mas equipos se desactiva el infinite scroll
          if (data.equipos.length === 0) {
            setHasMoreEquipos(false);
          } else {
            setHasMoreEquipos(data.hasMoreDrives);
          }
          setFiltersObtained(true);
        })
        .finally(() => {
          setLoadingDrives(false);
          setLoadingEquipos(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingDrives(false);
        });
    },
    []
  );

  //Get ALL Filters - getFilters
  const getDriveFilters = useCallback(
    async (PlantaId, ZonaId, storageFilters, onlyDefaultFilter) => {
      setLoadingEquipos(true);
      setLoadingFilters(true);
      if (ZonaId === null || ZonaId === undefined) {
        ZonaId = 0;
      }
      EquiposAPI.getDriveFilters(PlantaId, ZonaId)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          setDefaultFilters(data.defaultFilter);
          if (!onlyDefaultFilter) {
            setFiltersCleaned(data.driveFilter);
            if (storageFilters) {
              setDriveFilters(storageFilters);
            } else {
              setDriveFilters(data.driveFilter);
            }
          }
          setFiltersObtained(true);
        })
        .finally(() => {
          setLoadingFilters(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingFilters(false);
          setLoadingEquipos(false);
        });
    },
    []
  );

  //Get ALL check drives
  const getCheckDrives = useCallback(
    async (PlantaId, ZonaId, selectorFilter, searchQuery, driveFilters) => {
      setLoadingEquipos(true);
      if (ZonaId === null || ZonaId === undefined) {
        ZonaId = 0;
      }
      EquiposAPI.getCheckDrives(
        PlantaId,
        ZonaId,
        selectorFilter,
        searchQuery,
        driveFilters
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          setDrivesChecked(data.drivesId);
          setCheckDrivesInWarehouse(data.warehouseDrivesId);
          setCheckDrivesInactive(data.inactiveDrives);
        })
        .finally(() => {
          setLoadingEquipos(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingEquipos(false);
        });
    },
    []
  );
  //Refrescar cilco de vida de la planta
  const updateLifeCycle = () => {
    setLoadingEquipos(true);
    PlantasAPI.updateLifeCycleOfDrives(PlantaId)
      .then(async (response) => {
        if (response.ok) {
          callInfiniteScroll();
          return response.json();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .then((data) => {
        setDialogRefreshText(data);
        setDialogRefreshOpen(true);
        setLoadingEquipos(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingEquipos(false);
      });
  };

  //Busqueda con delay
  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (isFirstInput) {
        setIsFirstInput(false);
        var newFilters = [];
        if (driveFilters.length === 0) {
          newFilters = defaultFilters;
        } else {
          //Calculo de filtros
          newFilters = calculateFilterToSend(defaultFilters, driveFilters);
        }
        setEquipos([]);
        getInfiniteDrives(
          PlantaId,
          ZonaId,
          activeFilter,
          //actualLength
          0,
          searchQuery,
          //isNewQuery
          true,
          newFilters
        );
      }
    }, 600);
    return () => clearTimeout(searchDelay);
  }, [
    searchQuery,
    getInfiniteDrives,
    isFirstInput,
    PlantaId,
    ZonaId,
    activeFilter,
    driveFilters,
    defaultFilters,
  ]);

  //To fix navigation bug when go from Preventive Maintenance
  const forceNavigationNewEquipo = () => {
    window["AltidVars"] = {
      ...window["AltidVars"],
      Tabs_EquiposDetails: 0,
    };
    if (!fromTreeView) {
      if (ZonaId) {
        history.push(`./${ZonaId}/equipos/`);
      } else {
        history.push(`./${PlantaId}/equipos/`);
      }
    } else {
      if (ZonaId) {
        history.push(`./${PlantaId}/zonas/${ZonaId}/equipos/`);
      } else {
        history.push(`./${PlantaId}/equipos/`);
      }
    }
  };

  //get lista de marcas para revision equipos
  const getBrands = useCallback(async () => {
    return BrandAPI.get()
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(
            "Something went wrong on FrequencyConverterSearchEngine -> getBrands()"
          );
        }
      })
      .then((data) => {
        setBrands(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //update equipo en revision equipos
  const updateEquipo = async (inactivo, provisional) => {
    setLoadingEquipos(true);
    setChanged(false);
    return EquiposAPI.update({
      ...equipo,
      inactivo: inactivo === undefined ? equipo.inactivo : inactivo,
      provisional: provisional === undefined ? equipo.provisional : provisional,
    })
      .then(async (response) => {
        if (response.ok) {
          setErrors([]);
          var equiposN = equipos;
          equipo.provisional =
            provisional === undefined ? equipo.provisional : provisional;
          let ind = equipos.findIndex((e) => e.id === equipo.id);
          equiposN[ind] = equipo;
          setEquipos(equiposN);
          SnackbarSuccess(setGlobal, i18n.t("equipo.snackbar.update.success"));
          callDriveFilters();
          return response.json();
        } else {
          if (response.status === 500) {
            goBack();
            SnackbarError(setGlobal, i18n.t("equipo.snackbar.update.error"));
          }
          var json = await response.json();
          SnackbarError(setGlobal, i18n.t("equipo.snackbar.update.error"));
          setErrors(json.errors);
        }
        setLoadingEquipos(false);
      })
      .catch((error) => {
        setLoadingEquipos(false);
      });
  };

  //Llamada a infinite scroll
  const callReloadDriveFilter = () => {
    var newFilters = [];
    if (driveFilters.length === 0) {
      newFilters = driveFilters;
    } else {
      //Calculo de filtros
      newFilters = calculateFilterToSend(defaultFilters, driveFilters);
    }
    getInfiniteDrives(
      PlantaId,
      ZonaId,
      activeFilter,
      0,
      searchQuery,
      true,
      newFilters
    );
    callDriveFilters();
  };

  //Llamada a infinite scroll
  const callInfiniteScroll = () => {
    var newFilters = [];
    if (driveFilters.length === 0) {
      newFilters = driveFilters;
    } else {
      //Calculo de filtros
      newFilters = calculateFilterToSend(defaultFilters, driveFilters);
    }
    getInfiniteDrives(
      PlantaId,
      ZonaId,
      activeFilter,
      //actualLength
      equipos.length,
      searchQuery,
      //isNewQuery
      false,
      newFilters
    );
  };

  //Llamada a DefaultFiltersPara renovar
  const callDriveFilters = () => {
    getDriveFilters(PlantaId, ZonaId, false, true);
  };

  //Llamada a DefaultFiltersPara renovar
  const reloadDriveList = () => {
    setFirstRender(true);
    setFiltersObtained(false);
  };

  //Siguiente equipo en review equipos
  const siguienteEquipo = (confirm) => {
    let nextIndex = index + 1;
    if (nextIndex >= equipos.length) return;
    if (!confirm && changed) {
      confirmNextDriverDialog.current.open();
      return;
    }

    if (drivesChecked.length > 0) {
      while (!drivesChecked.includes(equipos[nextIndex].id)) {
        nextIndex++;
        if (nextIndex >= equipos.length) return;
      }
    }

    setEquipo(equipos[nextIndex]);
    setIndex(nextIndex);

    if (nextIndex > equipos.length - 5 && hasMoreEquipos) {
      callInfiniteScroll();
    }
  };

  //anterior equipo en revision equipos
  const anteriorEquipo = (confirm) => {
    let prevIndex = index - 1;
    if (prevIndex < 0) return;
    if (!confirm && changed) {
      confirmPastDriverDialog.current.open();
      return;
    }

    if (drivesChecked.length > 0) {
      while (!drivesChecked.includes(equipos[prevIndex].id)) {
        prevIndex--;
        if (prevIndex < 0) return;
      }
    }
    setEquipo(equipos[prevIndex]);
    setIndex(prevIndex);
  };

  //abrir revision equipos
  const openRevisionFunction = () => {
    setOpenRevision(true);
    if (drivesChecked.length > 0) {
      let index = 0;
      while (!drivesChecked.includes(equipos[index].id)) {
        index++;
      }
      setEquipo(equipos[index]);
    } else {
      setEquipo(equipos[0]);
    }
  };

  //cerrar revision equipos
  const closeRevisionFunction = () => {
    setOpenRevision(false);
    setEquipo(equipos[0]);
    setIndex(0);
  };

  //abrir menu de acciones
  const openActionsMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  //cerrar menu de acciones
  const handleClose = () => {
    setAnchorEl(null);
  };

  //abrir Dialog Emergency Stock
  const openEmergencyStock = () => {
    setAnchorEl(null);
    setOpenESDialog(true);
  };

  //cerrar Dialog Emergency Stock
  const closeEmergencyStock = () => {
    setOpenESDialog(false);
  };

  //abrir Dialog RFetrofit
  const openRetrofit = () => {
    setAnchorEl(null);
    setOpenRetroDialog(true);
  };

  //cerrar Dialog Retrofit
  const closeRetrofit = () => {
    setOpenRetroDialog(false);
  };

  //handle change equipo en revision equipos
  const handleChangeEquipo = (event) => {
    setChanged(true);

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setEquipo((prevEquipo) => ({ ...prevEquipo, [name]: value }));

    if (name === "brandId") {
      setEquipo((old) => ({ ...old, modelId: undefined, model: undefined }));
    }

    setEquipo((old) => ({ ...old, [name]: value }));
  };

  //Cambiar vista de la lista
  const onChangeViewMode = (event, mode) => {
    //Se hace scroll al inicio de la lista par aque no realice un scroll infinito
    const scrollableDiv = scrollableDivRef.current;
    scrollableDiv.scrollTop = 0;
    //Se cambia la vista
    if (mode) {
      setViewMode(mode);
      if (fromTreeView) {
        localStorage.setItem("treeViewMode", mode);
      } else {
        localStorage.setItem("driveViewMode", mode);
      }
      //Limitador de check - Limpiar checks cuando se cambia de filtro
      setDrivesChecked([]);
      setCheckDrivesInWarehouse([]);
      setCheckDrivesInactive([]);
      setcheckAllDrive(false);
    }
  };

  //Check individual desde DriveListView
  const handleCheckDrives = (event, drive) => {
    event.stopPropagation();
    if (drivesChecked.includes(drive.id)) {
      setDrivesChecked(drivesChecked.filter((id) => id !== drive.id));
      setCheckDrivesInWarehouse(
        checkDrivesInWarehouse.filter((id) => id !== drive.id)
      );
      setCheckDrivesInactive(
        checkDrivesInactive.filter((id) => id !== drive.id)
      );
      setcheckAllDrive(true);
    } else {
      setDrivesChecked([...drivesChecked, drive.id]);
      if (drive?.zona?.isWarehouse) {
        setCheckDrivesInWarehouse([...checkDrivesInWarehouse, drive.id]);
      }
      if (drive.cicloVida === 2) {
        setCheckDrivesInactive([...checkDrivesInactive, drive.id]);
      }
    }
  };

  //CheckAll
  const handleCheckAllDrives = () => {
    if (checkAllDrive) {
      setDrivesChecked([]);
      setCheckDrivesInWarehouse([]);
      setcheckAllDrive(false);
    } else {
      setcheckAllDrive(true);
      var newFilters = [];
      if (driveFilters.length === 0) {
        newFilters = defaultFilters;
      } else {
        //Calculo de filtros
        newFilters = calculateFilterToSend(defaultFilters, driveFilters);
      }

      getCheckDrives(PlantaId, ZonaId, activeFilter, searchQuery, newFilters);
    }
  };

  //Cambiar checkAll cuando se hace check individual
  useEffect(() => {
    if (drivesChecked.length > 0) {
      setcheckAllDrive(true);
    } else {
      setcheckAllDrive(false);
    }
  }, [drivesChecked]);

  //Cambiar selector de filtros
  const calculateFilterToSend = (defaultFilter, driveFilter) => {
    //Copia de filtros para modificar
    let resultFilter = { ...defaultFilter };

    // Comprobar atributo por atributo
    Object.keys(defaultFilter).forEach((atribute) => {
      // En caso de sorting
      if (atribute === "sorting") {
        resultFilter[atribute] = driveFilter[atribute];
        //para cambiar el valor de sortValue (color rojo de header)
        setSortValue(
          resultFilter.sorting.sortColumn + resultFilter.sorting.sortDirection
        );
      }
      //para los demas atributos
      if (atribute !== "sorting") {
        if (
          Array.isArray(defaultFilter[atribute]) &&
          defaultFilter[atribute].length > 0 &&
          defaultFilter[atribute][0] !== null &&
          typeof defaultFilter[atribute][0] === "object"
        ) {
          // Solo para los atributos que son objetos
          resultFilter[atribute] = defaultFilter[atribute].filter((obj) => {
            // comprobar que no este el objeto en el array
            return !driveFilter[atribute].some((eliminarObj) => {
              // Comparara objetos por propiedades
              return Object.entries(eliminarObj).every(
                ([key, value]) => obj[key] === value
              );
            });
          });
        } else {
          // Eliminar valores
          resultFilter[atribute] = defaultFilter[atribute].filter(
            (value) => !driveFilter[atribute].includes(value)
          );
        }
      }
    });

    return resultFilter;
  };

  //Cambiar filtros
  const handleFilterChange = (filters, selectValue) => {
    setcheckAllDrive(false);
    setCheckDrivesInWarehouse([]);
    setDrivesChecked([]);
    setCheckDrivesInactive([]);

    var newFilters = [];
    var filtersToSave = [];
    var selectorValue = 0;
    if (selectValue) {
      selectorValue = selectValue;
    }

    if (filters.length === 0) {
      newFilters = defaultFilters;
      filtersToSave = filtersCleaned;
    } else {
      //para guardar sin calulos o dejarlos vacios
      filtersToSave = filters;
      //Calculo de filtros
      newFilters = calculateFilterToSend(defaultFilters, filters);
    }
    setEquipos([]);
    const storageFilter = {
      driveFilters: filtersToSave,
      plantId: PlantaId,
      zoneId: ZonaId,
      activeFilter: selectorValue,
    };
    localStorage.setItem("savedFilters", JSON.stringify(storageFilter));
    //setStorageFilter(localFilters);
    getInfiniteDrives(
      PlantaId,
      ZonaId,
      selectorValue,
      //actualLength
      0,
      searchQuery,
      //isNewQuery
      true,
      newFilters
    );
  };

  //Cambiar selector de filtros
  const handleSelectorChange = (selectValue) => {
    //Llamada a cambio de filtros
    handleFilterChange(driveFilters, selectValue);
    //Limitador de check - Limpiar checks cuando se cambia de filtro
    setDrivesChecked([]);
    setCheckDrivesInWarehouse([]);
    setCheckDrivesInactive([]);
    setcheckAllDrive(false);
  };

  //Sincronizar animacion de loading
  useEffect(() => {
    if (loadingDrives || loadingFilters) {
      setLoadingDrives(true);
    } else {
      setLoadingDrives(false);
    }
  }, [loadingDrives, loadingFilters]);

  //Detectar cambio de zona desde treeview
  useEffect(() => {
    if (changeZone) {
      setChangeZone(false);
      setFirstRender(true);
    }
  }, [changeZone, setChangeZone]);

  //Enlazar scroll de la lista con el header
  const handleScroll = () => {
    const scrollLeft = scrollableDivRef.current.scrollLeft;
    if (headerRef.current) {
      headerRef.current.scrollLeft = scrollLeft;
    }
  };

  //Comprobar si se han activado los filtros para el boton de limpiar filtros
  useEffect(() => {
    //check sorting
    if (sortValue !== "IdAsc") {
      setFilterChanged(true);
      return;
    }
    //check filters
    for (let atribute in driveFilters) {
      if (
        Array.isArray(driveFilters[atribute]) &&
        driveFilters[atribute].length > 0 &&
        atribute !== "sorting"
      ) {
        setFilterChanged(true);
        return;
      }
    }
    //Ningun cambio aqui
    setFilterChanged(false);
  }, [driveFilters, sortValue]);

  //Llamada para cargar default filters
  useEffect(() => {
    if (!filtersObtained) {
      getDriveFilters(PlantaId, ZonaId, false, true);
    }
  }, [PlantaId, ZonaId, filtersObtained, getDriveFilters]);

  //primera funcion llamada getEquipos
  useEffect(() => {
    if (firstRender && filtersObtained) {
      setEquipos([]);
      setFirstRender(false);
      setEquipos([]);
      if (fromTreeView) {
        setViewMode(localStorage.getItem("treeViewMode") || "List");
      } else {
        setViewMode(localStorage.getItem("driveViewMode") || "List");
      }
      //Filtros guardados local storage
      const savedFiltersString = localStorage.getItem("savedFilters");
      const storageFilters = savedFiltersString
        ? JSON.parse(savedFiltersString)
        : [];
      getBrands();
      if (
        //Comprobar que hay filtros y son de la misma planta y zona
        storageFilters &&
        storageFilters?.plantId?.toString() === PlantaId?.toString() &&
        storageFilters?.zoneId?.toString() === ZonaId?.toString()
      ) {
        getDriveFilters(PlantaId, ZonaId, storageFilters.driveFilters, false);

        //Comprobar que hay filtros
        var newFilters = [];
        if (storageFilters?.driveFilters?.length === 0) {
          newFilters = defaultFilters;
        } else {
          //Calculo de filtros
          newFilters = calculateFilterToSend(
            defaultFilters,
            storageFilters.driveFilters
          );
        }
        //Llamada a equipos
        getInfiniteDrives(
          PlantaId,
          ZonaId,
          //selectorFilter,
          storageFilters.activeFilter,
          //actualLength,
          0,
          //searchQuery,
          "",
          //isNewQuery,
          true,
          newFilters
        );
        //Cambiar el selector de filtros para mostrar el puto boton
        setActiveFilter(storageFilters.activeFilter);
      } else {
        //limpiar filtro porque se ha cambiado de planta o zona
        localStorage.removeItem("savedFilters");
        getDriveFilters(PlantaId, ZonaId);
        getInfiniteDrives(PlantaId, ZonaId);
      }
    }
  }, [
    getInfiniteDrives,
    PlantaId,
    ZonaId,
    getBrands,
    getDriveFilters,
    firstRender,
    defaultFilters,
    filtersObtained,
    driveFilters,
    fromTreeView,
  ]);

  return (
    <RelativeBackdrop hidden={!loadingEquipos}>
      <div className={commonStyles.mainContainer}>
        <div className={commonStyles.searchActionContainer}>
          <div
            className={
              fromTreeView ? styles.treeViewItemCount : styles.itemCount
            }
          >
            {i18n.t("planta.nDrives")} <b>{nDrivers}</b>
          </div>
          <div
            className={
              fromTreeView
                ? commonStyles.searchFieldIntreeView
                : commonStyles.searchField
            }
          >
            <SearchField
              inputBaseProps={{
                placeholder: i18n.t("common.search"),
                value: searchQuery,
                onChange: (event) => {
                  setIsFirstInput(true);
                  setDrivesChecked([]);
                  setCheckDrivesInactive([]);
                  setCheckDrivesInWarehouse([]);
                  setcheckAllDrive(false);
                  setSearchQuery(event.target.value);
                },
              }}
              goBack={goBack}
            />
            <>
              <Button
                data-testid="drives-actionButton"
                aria-haspopup="true"
                variant="contained"
                className={commonStyles.primaryButtonRaw}
                onClick={openActionsMenu}
              >
                <MoreVert className={styles.addCircleOutline} />
                {i18n.t("planta.actions.actionButton")}
              </Button>
              <Menu
                className={styles.menuMargin}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {!readOnly && (
                  //Nuevo Equipo
                  <Tooltip
                    arrow
                    placement="left"
                    title={i18n.t("planta.actions.toolTip.newDrive")}
                  >
                    <MenuItem
                      data-testid="drives-newDriveButton"
                      onClick={forceNavigationNewEquipo}
                      className={styles.menuItemSpace}
                    >
                      <ListItemIcon>
                        <AddCircleOutline
                          className={styles.addCircleOutlineNewDrive}
                        />
                      </ListItemIcon>
                      <ListItemText className={styles.menuActiveText}>
                        {i18n.t("common.newDrive")}
                      </ListItemText>
                    </MenuItem>
                  </Tooltip>
                )}
                {/* Review Equipos */}
                <Tooltip
                  arrow
                  placement="left"
                  title={i18n.t("planta.actions.toolTip.review")}
                >
                  <MenuItem
                    data-testid="drives-reviewButton"
                    className={styles.menuItemSpace}
                    onClick={() => {
                      openRevisionFunction();
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <MenuOpen className={styles.addCircleOutline} />
                    </ListItemIcon>
                    <ListItemText>
                      {i18n.t("planta.revisionEquipos.revision")}
                    </ListItemText>
                  </MenuItem>
                </Tooltip>

                {!readOnly && stockPermission && (
                  <div>
                    {/* Stock Emergencia*/}
                    <Tooltip
                      arrow
                      placement="left"
                      title={
                        isPremium
                          ? drivesChecked.length <= 0
                            ? i18n.t("planta.actions.toolTip.disabledEmergency")
                            : i18n.t(
                                "planta.actions.toolTip.enabledEmergency",
                                {
                                  nDrives: drivesChecked.length,
                                }
                              )
                          : i18n.t("common.isPremium")
                      }
                    >
                      <div>
                        <MenuItem
                          data-testid="drives-emergencyStockButton"
                          className={styles.menuItemSpace}
                          disabled={drivesChecked.length <= 0 || !isPremium}
                          onClick={() => {
                            openEmergencyStock();
                            handleClose();
                          }}
                        >
                          <ListItemIcon>
                            <Build className={styles.addCircleOutline} />
                          </ListItemIcon>
                          <ListItemText>
                            {i18n.t("planta.actions.emergencyStockOption")}
                          </ListItemText>
                        </MenuItem>
                      </div>
                    </Tooltip>
                    {/* Retrofit */}
                    <Tooltip
                      arrow
                      placement="left"
                      title={
                        isPremium
                          ? drivesChecked.length <= 0
                            ? i18n.t("planta.actions.toolTip.disabledRetrofit")
                            : i18n.t("planta.actions.toolTip.enabledRetrofit", {
                                nDrives: drivesChecked.length,
                              })
                          : i18n.t("common.isPremium")
                      }
                    >
                      <div>
                        <MenuItem
                          data-testid="drives-retrofitButton"
                          className={styles.menuItemSpace}
                          disabled={drivesChecked.length <= 0 || !isPremium}
                          onClick={() => {
                            openRetrofit();
                            handleClose();
                          }}
                        >
                          <ListItemIcon>
                            <FindReplace className={styles.addCircleOutline} />
                          </ListItemIcon>
                          <ListItemText>
                            {i18n.t("planta.actions.retrofitOption")}
                          </ListItemText>
                        </MenuItem>
                      </div>
                    </Tooltip>
                    {/* Refresh Ciclo de vida */}
                    <Tooltip
                      arrow
                      placement="left"
                      title={i18n.t("planta.actions.toolTip.refresh")}
                    >
                      <div>
                        <MenuItem
                          data-testid="drives-refreshButton"
                          className={styles.menuItemSpace}
                          onClick={() => {
                            updateLifeCycle();
                            handleClose();
                          }}
                        >
                          <ListItemIcon>
                            <Refresh className={styles.addCircleOutline} />
                          </ListItemIcon>
                          <ListItemText>
                            {i18n.t("planta.actions.refreshOption")}
                          </ListItemText>
                        </MenuItem>
                      </div>
                    </Tooltip>
                  </div>
                )}
                <Tooltip
                  arrow
                  placement="left"
                  title={i18n.t("planta.actions.toolTip.modeView")}
                >
                  <div>
                    <MenuItem
                      data-testid="drives-viewModeButton"
                      className={styles.menuItemSpace}
                      onClick={(e) => {
                        if (viewMode === "List") {
                          onChangeViewMode(e, "Card");
                        } else {
                          onChangeViewMode(e, "List");
                        }
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        {viewMode === "Card" ? (
                          <Reorder className={styles.addCircleOutline} />
                        ) : (
                          <ViewCarousel className={styles.addCircleOutline} />
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        {viewMode === "Card"
                          ? i18n.t("planta.listMode")
                          : i18n.t("planta.cardMode")}
                      </ListItemText>
                    </MenuItem>
                  </div>
                </Tooltip>

                <Divider />
                {/* Filtros */}
                <Tooltip
                  arrow
                  placement="left"
                  title={i18n.t("planta.actions.toolTip.genericFilter")}
                >
                  <div>
                    <MenuItem
                      className={styles.menuItemSpace}
                      data-testid="drives-filter"
                    >
                      <SelectFrequencyConvertersSearchEngineFilter
                        //Filtro de busqueda
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                        handleSelectorChange={handleSelectorChange}
                      />
                    </MenuItem>
                  </div>
                </Tooltip>
              </Menu>
            </>

            {/* Limpiar filtros */}
            <div className={styles.ghostFilterButtonContainer}>
              {(filterChanged || activeFilter !== 0) && (
                <Tooltip
                  arrow
                  placement="right"
                  title={i18n.t("planta.actions.toolTip.disableFilters")}
                >
                  <Button
                    data-testid="drives-cleanFilterButton"
                    variant="outlined"
                    className={styles.filterButton}
                    onClick={() => {
                      setDriveFilters(filtersCleaned);
                      handleFilterChange(filtersCleaned);
                      setActiveFilter(0);
                      setSortValue("IdAsc");
                    }}
                    onMouseEnter={() => setHoverFilterButton(true)}
                    onMouseLeave={() => setHoverFilterButton(false)}
                  >
                    {!hoverFilterButton ? (
                      <FilterList className={styles.saveIcon} />
                    ) : (
                      <DisableFilter color={globalColors.accentColor} />
                    )}
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        {viewMode !== "Card" && !loadingEquipos && (
          <DriveListHeader
            driveFilters={driveFilters}
            setDriveFilters={setDriveFilters}
            defaultFilters={defaultFilters}
            handleFilterChange={handleFilterChange}
            handleCheckAllDrives={handleCheckAllDrives}
            checkAllDrive={checkAllDrive}
            headerRef={headerRef}
            sortValue={sortValue}
            setSortValue={setSortValue}
          />
        )}
        {viewMode === "Card" ? (
          <div
            id="scrollableDiv"
            data-testid="driveCardMode-Container"
            className={cardStyles.driveListCardContainer}
            ref={scrollableDivRef}
            onScroll={handleScroll}
          >
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              className={cardStyles.driveCardContainer}
              dataLength={equipos.length}
              next={() => callInfiniteScroll()}
              hasMore={hasMoreEquipos}
              loader={<RelativeBackdrop hidden={!loadingEquipos} />}
            >
              {equipos.map((equipo, indx) => {
                return (
                  <div key={indx}>
                    <Link
                      to={
                        //Para evitar el Unauthorised
                        !fromTreeView
                          ? ZonaId
                            ? `./${ZonaId}/equipos/${equipo.id}`
                            : `./${PlantaId}/equipos/${equipo.id}`
                          : `./${PlantaId}/equipos/${equipo.id}`
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <EquipoCard equipo={equipo} />
                    </Link>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          <div
            id="scrollableDiv"
            data-testid="driveListMode-Container"
            className={cardStyles.driveListContainer}
            ref={scrollableDivRef}
            onScroll={handleScroll}
          >
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              className={cardStyles.driveListInfiniteContainer}
              dataLength={equipos.length}
              next={() => callInfiniteScroll()}
              hasMore={hasMoreEquipos}
              loader={<RelativeBackdrop hidden={!loadingEquipos} />}
            >
              {equipos.map((equipo, indx) => {
                return (
                  <div key={indx}>
                    <DriveListView
                      equipo={equipo}
                      zonaId={ZonaId}
                      plantaId={PlantaId}
                      history={history}
                      handleCheckDrives={handleCheckDrives}
                      drivesChecked={drivesChecked}
                      fromTreeView={fromTreeView}
                    />
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </div>
      <DialogRevisionEquipos
        open={openRevision}
        closeFunction={closeRevisionFunction}
        equipo={equipo}
        setEquipo={setEquipo}
        brands={brands}
        siguienteEquipo={siguienteEquipo}
        anteriorEquipo={anteriorEquipo}
        handleInputChange={handleChangeEquipo}
        guardarEquipo={updateEquipo}
        readOnly={readOnly}
      />

      <DialogEmergencyStock
        setDrivesChecked={setDrivesChecked}
        setCheckDrivesInWarehouse={setCheckDrivesInWarehouse}
        drivesChecked={drivesChecked}
        checkDrivesInWarehouse={checkDrivesInWarehouse}
        plantaId={PlantaId}
        open={openESDialog}
        closeFunction={closeEmergencyStock}
      />

      <DialogRetrofitProposal
        setDrivesChecked={setDrivesChecked}
        setCheckDrivesInactive={setCheckDrivesInactive}
        drivesChecked={drivesChecked}
        checkDrivesInactive={checkDrivesInactive}
        plantaId={PlantaId}
        open={openRetroDialog}
        closeFunction={closeRetrofit}
        reloadDriveList={reloadDriveList}
      />

      <ScrollDialog
        open={dialogRefreshOpen}
        title={i18n.t("dataTableProps.dialog.refresh")}
        text={dialogRefreshText}
        handleClose={() => {
          setDialogRefreshOpen(false);
          reloadDriveList();
        }}
      />
      {/* @ts-ignore */}
      <AlertDialog
        ref={confirmNextDriverDialog}
        title={i18n.t("planta.revisionEquipos.alert.title")}
        text={i18n.t("planta.revisionEquipos.alert.text")}
        cancelText={i18n.t("planta.revisionEquipos.alert.cancel")}
        confirmText={i18n.t("planta.revisionEquipos.alert.accept")}
        confirmAction={() => {
          setChanged(false);
          confirmNextDriverDialog.current.close();
          siguienteEquipo(true);
        }}
        cancelAction={() => confirmNextDriverDialog.current.close()}
      />
      {/* @ts-ignore */}
      <AlertDialog
        ref={confirmPastDriverDialog}
        title={i18n.t("planta.revisionEquipos.alert.title")}
        text={i18n.t("planta.revisionEquipos.alert.text")}
        cancelText={i18n.t("planta.revisionEquipos.alert.cancel")}
        confirmText={i18n.t("planta.revisionEquipos.alert.accept")}
        confirmAction={() => {
          setChanged(false);
          confirmPastDriverDialog.current.close();
          anteriorEquipo(true);
        }}
        cancelAction={() => confirmPastDriverDialog.current.close()}
      />
    </RelativeBackdrop>
  );
}
