import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Paper,
  Tab,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import {
  Business,
  CalendarToday,
  LocalOffer,
  Build,
  Attachment,
  MailOutline,
  PieChart,
  MenuOpen
} from "@material-ui/icons";
import AlertDialog from "../../../components/AlertDialog";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import EventDialog from "../../../components/EventDialog";
import i18n from "../../../locales/i18n";
import {
  PlantasAPI,
  EquipoScheduledPreventiveMaintenancesAPI,
  EquipoActividadesAPI,
} from "../../../services/API";
import moment from "moment";
import Calendar from "rc-year-calendar";
import "rc-year-calendar/locales/rc-year-calendar.es";
import AuthService from "../../../services/AuthService";
import InventarioTable from "../../molecule/inventarioTable/InventarioTable";
import PlantaStockEmergenciaTable from "../../molecule/plantaStockEmergencia/PlantaStockEmergenciaTable";
import AssessmentTable from "../../../components/AssessmentTable";
import ZonaSearchAndAdd from "../../organism/ZonaSearchAndAdd/ZonaSearchAndAdd";
import CardPlanta from "../../organism/cardPlanta/cardPlanta";
import {
  capitalizeFirstLetter,
  SnackbarSuccess,
  SnackbarError,
  CloneProps,
} from "../../../helpers/Common";
import PlantaBase from "../../../models/plantaBase";
import TabDocumentosPlanta from "../../organism/tabDocumentosPlanta/tabDocumentosPlanta";
import AppContext from "../../../contexts/AppContext";
import { DetailTabs_Style, Common_Style } from "../../../assets/css/Styles";
import SiteAssessmentVersionsTable from "../../molecule/SiteAssessmentVersionsTable/SiteAssessmentVersionsTable";
import TabReportPlanta from "../../organism/tabReportPlanta/TabReportPlanta";
import DriveIcon from "../../atoms/driveIcon";
import TabPlantZone from "../../organism/tabPlantZones/TabPlantZone";
import PlantaLogs from "../../molecule/plantaLogs/PlantaLogs";

export default function PlantasDetails(props) {
  const { keyuser, match, location } = props;
  const id = match.params.id;
  const clienteId = match.params.clienteId;
  const readOnly = props.readOnly && !keyuser;
  const isPremium = AuthService.plantPremium(id);
  const isGestor = AuthService.isGestorFromPlant(id);
  const isDSC = AuthService.hasRoleDSC();
  const plantaB = new PlantaBase({});
  plantaB.clienteId = clienteId;
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [planta, setPlanta] = useState(plantaB);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [eventDialogOpen, setEventDialogOpen] = useState(false);
  const [eventDialogEvents, setEventDialogEvents] = useState([]);
  const [errors, setErrors] = useState({});
  const [inventoryTabValue, setInventoryTabValue] = useState(0);
  const [events, setEvents] = useState([]);
  const [scheduledPreventiveMaintenances, setScheduledPreventiveMaintenances] =
    useState([]);
  const [equipoActividades, setEquipoActividades] = useState([]);
  const [isGestorThisPartner, setIsGestorThisPartner] = useState(false);
  const [salesforceIdChanged, setSalesforceIdChanged] = useState(false);

  const IsPartnerPlanta = AuthService.getPartnerId() === planta.partnerId;

  const deleteDialog = useRef(null);
  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    window["AltidVars"] = {
      ...window["AltidVars"],
      Tabs_PlantasDetails: newValue,
    };
  };

  const handleInventoryChangeTab = (event, newValue) => {
    setInventoryTabValue(newValue);
    window["AltidVars"] = {
      ...window["AltidVars"],
      Tabs_Inventory: newValue,
    };
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "salesforceId") {
      setSalesforceIdChanged(true);
    }
    setPlanta((prevState) => ({ ...prevState, [name]: value }));
    if (name === "isExchange" && !value) {
      setPlanta((prevState) => ({ ...prevState, appUserId: null }));
    }
    const errorName = name ? capitalizeFirstLetter(name) : null;
    if (errors[errorName] || errors["salesforceIdDuplicated"]) {
      if (errors["salesforceIdDuplicated"]) {
        setErrors((e) => ({ ...errors, salesforceIdDuplicated: undefined }));
      } else {
        setErrors((e) => ({ ...errors, [errorName]: undefined }));
      }
    } else if (errors[name]) {
      setErrors((e) => ({ ...errors, [name]: undefined }));
    }
  };

  const handlePlantInfoBySalesforce = (newValues) => {
    let objAux = {};
    const newValuesKeys = Object.keys(newValues);
    for (let i = 0; i <= newValuesKeys.length; i++) {
      const name = newValuesKeys[i];
      objAux = { ...objAux, [name]: newValues[name] };
    }
    setPlanta((prevState) => ({
      ...prevState,
      ...objAux,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      Denominacion: undefined,
      Direccion: undefined,
      CP: undefined,
      Poblacion: undefined,
      Provincia: undefined,
      CountryId: undefined,
    }));
  };

  const onEventClick = (e) => {
    setEventDialogEvents(e.events);
    setEventDialogOpen(e.events.length ? true : false);
  };

  const deletePlanta = async () => {
    setLoading(true);

    PlantasAPI.remove(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 404) {
            SnackbarError(setGlobal, i18n.t("planta.snackbar.deleted.error"));
            goBack();
            throw new Error("Something went wrong");
          }
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        SnackbarSuccess(setGlobal, i18n.t("planta.snackbar.deleted.success"));
        props.history.goBack();
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("planta.snackbar.deleted.error"));
        setLoading(false);
      });
  };

  const getPlanta = useCallback(async () => {
    setLoading(true);

    return PlantasAPI.get(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setPlanta(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id]);

  const updatePlanta = async () => {
    setLoading(true);
    const updatePlanta = new PlantaBase(planta);
    PlantasAPI.update(updatePlanta, salesforceIdChanged)
      .then(async (response) => {
        if (response.ok) {
          if (!planta.denominacion) {
            props.setNavBarTitle(
              i18n.t("planta.titulo") + " " + planta.denominacion
            );
          }
          SnackbarSuccess(setGlobal, i18n.t("planta.snackbar.update.success"));
        } else {
          if (response.status === 404) {
            SnackbarError(setGlobal, i18n.t("planta.snackbar.update.error"));
            goBack();
            throw new Error("Something went wrong");
          }
          var json = await response.json();
          if (json?.errors) {
            setErrors(json.errors);
          }
          SnackbarError(setGlobal, i18n.t("planta.snackbar.update.error"));
        }
        setLoading(false);
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("planta.snackbar.update.error"));
        setLoading(false);
      });
  };

  const addPlanta = async () => {
    setLoading(true);
    if (!planta.partnerId && !keyuser && !isDSC) {
      planta.partnerId = AuthService.getPartnerId();
    }
    PlantasAPI.add(planta)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var json = await response.json();
          if (json?.errors) {
            setErrors(json.errors);
          }
          SnackbarError(setGlobal, i18n.t("planta.snackbar.new.error"));
        }
      })
      .then((dataPlanta) => {
        SnackbarSuccess(setGlobal, i18n.t("planta.snackbar.new.success"));
        AuthService.addTempAccessToPlant(dataPlanta.id);
        setLoading(false);
        props.history.replace(`/plantas/${dataPlanta.id}`);
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("planta.snackbar.new.error"));
        console.error(error);
        setLoading(false);
      });
  };

  const resetPlanta = () => {
    if (id) {
      Promise.all([getPlanta()]).then(() => setLoading(false));
    } else {
      setPlanta(new PlantaBase({}));
    }
    setErrors({});
  };

  const getScheduledPreventiveMaintenanceByPlant = useCallback(async () => {
    return EquipoScheduledPreventiveMaintenancesAPI.getScheduledByPlant(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setScheduledPreventiveMaintenances(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const getEquipoActividadesByPlant = useCallback(async () => {
    return EquipoActividadesAPI.getActivityByPlant(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setEquipoActividades(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const downloadMantenimientosPrevistos = () => {
    setLoading(true);
    PlantasAPI.downloadMantenimientosPrevistos(planta.id, i18n.locale)
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download =
          i18n.locale === "en"
            ? "Planned Maintenance.xlsx"
            : "Mantenimientos Previstos.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        setLoading(false);
      });
  };

  const goBack = () => {
    if (props.history.length > 1) {
      props.history.goBack();
    } else {
      props.history.replace(`/plantas`);
    }
  };

  const requestMaintenance = () => {
    setLoading(true);
    EquipoScheduledPreventiveMaintenancesAPI.requestPlantMaintenance(id)
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          SnackbarSuccess(setGlobal, i18n.t("maintenance.request.success"));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        setLoading(false);
        SnackbarError(setGlobal, i18n.t("maintenance.request.error"));
        console.error(error);
      });
  };

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
  }, []);

  useEffect(() => {
    if (location.state && location.state.tabPlantaValue) {
      setTabValue(location.state.tabPlantaValue);
    }
  }, [location.state]);

  useEffect(() => {
    if (planta.denominacion) {
      setGlobal((prev) => ({
        ...prev,
        pageTitle: i18n.t("planta.titulo") + " " + planta.denominacion,
      }));
    } else {
      setGlobal((prev) => ({ ...prev, pageTitle: i18n.t("planta.titulo") }));
    }
  }, [setGlobal, planta.denominacion]);

  useEffect(() => {
    if (
      id &&
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_PlantasDetails !== undefined
    ) {
      setTabValue(window["AltidVars"].Tabs_PlantasDetails);
    }
    if (
      id &&
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_Inventory !== undefined
    ) {
      setInventoryTabValue(window["AltidVars"].Tabs_Inventory);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      Promise.all([
        getPlanta(),
        getScheduledPreventiveMaintenanceByPlant(),
        getEquipoActividadesByPlant(),
      ]).then(() => setLoading(false));
    }
  }, [
    id,
    getPlanta,
    getScheduledPreventiveMaintenanceByPlant,
    getEquipoActividadesByPlant,
  ]);

  useEffect(() => {
    const events = [];

    if (equipoActividades) {
      equipoActividades.forEach((actividad) => {
        events.push({
          isTask: true,
          equipoId: actividad.equipo.id,
          estado: actividad.estado,
          name: `${actividad.equipo.nombre} - ${actividad.descripcion}`,
          description: `${moment(actividad.fecha).format("DD/MM/YYYY")} - ${
            actividad.appUser.nombre
          } ${actividad.appUser.apellidos}`,
          startDate: new Date(actividad.fecha),
          endDate: new Date(actividad.fecha),
          color: actividad.estado === 0 ? "#969696" : "#969696",
        });
      });
    }

    if (scheduledPreventiveMaintenances) {
      scheduledPreventiveMaintenances.forEach((scheduledPM) => {
        events.push({
          equipoId: scheduledPM.equipo.id,
          name:
            scheduledPM.type === 3
              ? i18n.t("maintenance.altualScheduled.moduleBase") +
                ` - ${scheduledPM.equipo.nombre || scheduledPM.equipo.sn}`
              : `${i18n.t("common.modulo")} ${scheduledPM.type + 1} - ${
                  scheduledPM.equipo.nombre || scheduledPM.equipo.sn
                }`,
          startDate: new Date(scheduledPM.scheduledDate),
          endDate: new Date(scheduledPM.scheduledDate),
          type: scheduledPM.type,
          color:
            scheduledPM.type === 0
              ? "#969696"
              : scheduledPM.type === 1
              ? "#969696"
              : scheduledPM.type === 2
              ? "#969696"
              : "#969696",
          state: scheduledPM.state,
        });
      });
    }

    setEvents(events);
  }, [scheduledPreventiveMaintenances, equipoActividades]);

  useEffect(() => {
    if (planta.partnerId) {
      setIsGestorThisPartner(AuthService.isGestorFromPartner(planta.partnerId));
    }
  }, [planta.partnerId]);

  return (
    <div className={commonStyles.mainContainer}>
      <Paper className={styles.plantPaper} square>
        <Tabs
          scrollButtons="on"
          variant="scrollable"
          indicatorColor="secondary"
          textColor="inherit"
          classes={{ indicator: styles.tabsHeader }}
          value={tabValue}
          onChange={handleChangeTab}
        >
          <Tab
            value={0}
            label={
              <span className={styles.detailTabLabel}>
                {i18n.t("planta.tituloCard")}
              </span>
            }
            icon={<Business />}
            id="simple-tab-0"
            disabled={loading}
            data-testid="plantTab-plant"
          />
          {id && (
            <Tab
              value={1}
              label={
                <span className={styles.detailTabLabel}>
                  {i18n.t("equipo.tituloCard")}
                </span>
              }
              icon={<DriveIcon color={"#212121"} />}
              id="simple-tab-1"
              disabled={loading}
              data-testid="plantTab-drives"
            />
          )}
          {id && (
            <Tab
              value={2}
              label={
                <span className={styles.detailTabLabel}>
                  {i18n.t("report.title")}
                </span>
              }
              icon={<PieChart />}
              id="simple-tab-2"
              disabled={loading}
              data-testid="plantTab-report"
            />
          )}
          {id && (
            <CloneProps value={3}>
              {(tabProps) => (
                <Tooltip
                  title={i18n.t("common.isPremium")}
                  placement={"bottom"}
                  disableHoverListener={isPremium}
                  arrow
                >
                  <div>
                    <Tab
                      {...tabProps}
                      value={3}
                      label={
                        <span className={styles.detailTabLabel}>
                          {i18n.t("maintenance.title")}
                        </span>
                      }
                      onClick={(e) => handleChangeTab(e, 3)}
                      icon={<CalendarToday />}
                      id="simple-tab-3"
                      disabled={loading || !isPremium}
                      data-testid="plantTab-maintenance"
                    />
                  </div>
                </Tooltip>
              )}
            </CloneProps>
          )}
          {id && (
            <Tab
              value={4}
              label={
                <span className={styles.detailTabLabel}>
                  {i18n.t("planta.documents.title")}
                </span>
              }
              icon={<Attachment />}
              id="simple-tab-4"
              disabled={loading}
              data-testid="plantTab-documents"
            />
          )}
          {id && (
            <Tab
              value={5}
              label={
                <span className={styles.detailTabLabel}>
                  {i18n.t("planta.inventario")}
                </span>
              }
              icon={<LocalOffer />}
              id="simple-tab-5"
              disabled={loading}
              data-testid="plantTab-inventory"
            />
          )}
          {id && (
            <CloneProps value={6}>
              {(tabProps) => (
                <Tooltip
                  title={i18n.t("common.isPremium")}
                  placement={"bottom"}
                  disableHoverListener={isPremium}
                  arrow
                >
                  <div>
                    <Tab
                      {...tabProps}
                      value={6}
                      label={
                        <span className={styles.detailTabLabel}>
                          {i18n.t("planta.emergencyStock")}
                        </span>
                      }
                      onClick={(e) => handleChangeTab(e, 6)}
                      icon={<Build />}
                      id="simple-tab-6"
                      disabled={loading || !isPremium}
                      data-testid="plantTab-emergencyStock"
                    />
                  </div>
                </Tooltip>
              )}
            </CloneProps>
          )}
          {id && (
            <Tab
              value={7}
              label={
                <span className={styles.detailTabLabel}>
                  {i18n.t("planta.logs")}
                </span>
              }
              icon={<MenuOpen />}
              id="simple-tab-5"
              disabled={loading}
              data-testid="plantTab-inventory"
            />
          )}
        </Tabs>
      </Paper>
      {tabValue === 0 && (
        <RelativeBackdrop hidden={!loading}>
          {/* Plant details form */}
          <CardPlanta
            planta={planta}
            errors={errors}
            handleInputChange={handleInputChange}
            id={id}
            deleteDialog={deleteDialog}
            updatePlanta={updatePlanta}
            addPlanta={addPlanta}
            IsPartnerPlanta={IsPartnerPlanta}
            goBack={goBack}
            resetPlanta={resetPlanta}
            handlePlantInfoBySalesforce={handlePlantInfoBySalesforce}
            setErrors={setErrors}
            {...props}
          />
          {id && (
            <ZonaSearchAndAdd
              plantaId={id}
              readOnly={readOnly}
              history={props.history}
              goBack={goBack}
            />
          )}
        </RelativeBackdrop>
      )}
      {tabValue === 1 && (
        <RelativeBackdrop hidden={!loading}>
          {id && (
            <TabPlantZone
              plantaId={id}
              readOnly={readOnly}
              isPremium={isPremium}
              history={props.history}
              goBack={goBack}
              setErrors={setErrors}
            />
          )}
        </RelativeBackdrop>
      )}
      {tabValue === 2 && (
        <TabReportPlanta
          history={props.history}
          plant={planta}
          isPremium={isPremium}
        />
      )}
      {tabValue === 3 && (
        <RelativeBackdrop hidden={!loading}>
          <div className={commonStyles.mainContainer}>
            <div className={commonStyles.headerContainer}>
              <Card style={{ width: "100%" }}>
                <CardHeader
                  data-testid="maintance-pendingCard"
                  avatar={
                    <Avatar
                      aria-label="avatar"
                      className={styles.tabHeaderAvatar}
                    >
                      {
                        events.filter(
                          (e) =>
                            !e.isTask &&
                            e.startDate <
                              new Date().setMonth(new Date().getMonth() + 12)
                        ).length
                      }
                    </Avatar>
                  }
                  title={i18n.t("maintenance.plantTab.maintenance")}
                  subheader={i18n.t("maintenance.plantTab.pending")}
                />
              </Card>
              <Card style={{ width: "100%" }}>
                <CardHeader
                  data-testid="maintance-tasksHeader"
                  avatar={
                    <Avatar
                      aria-label="avatar"
                      className={styles.tabHeaderAvatar}
                    >
                      {events.filter((e) => e.isTask && e.estado < 2).length}
                    </Avatar>
                  }
                  title={i18n.t("maintenance.plantTab.tasks")}
                  subheader={i18n.t("maintenance.plantTab.tasksStatus")}
                />
              </Card>
            </div>
            <div className={commonStyles.headerContainer}>
              <div className={commonStyles.header}>
                {/* <Button
                  onClick={() => downloadMantenimientosPrevistos()}
                  variant="contained"
                  startIcon={<GetApp />}
                  className={commonStyles.primaryButtonRaw}
                >
                  {i18n.t("maintenance.downloadMaintenance")}
                </Button> */}
                <Button
                  onClick={() => requestMaintenance()}
                  variant="outlined"
                  startIcon={<MailOutline />}
                  className={commonStyles.secondaryButtonRaw}
                >
                  {i18n.t("maintenance.request.button")}
                </Button>
              </div>
            </div>
            <Paper>
              <Calendar
                language={i18n.locale}
                enableContextMenu={false}
                dataSource={events}
                onDayClick={onEventClick}
              />
            </Paper>
          </div>
        </RelativeBackdrop>
      )}
      {tabValue === 4 && (
        <TabDocumentosPlanta
          goBack={goBack}
          planta={planta}
          readOnly={readOnly}
          isPremium={isPremium}
        />
      )}
      {tabValue === 5 && (
        <>
          <Paper square>
            <Tabs
              data-testid="inventory-tabs"
              value={inventoryTabValue}
              onChange={handleInventoryChangeTab}
              centered
              variant="standard"
              indicatorColor="secondary"
              textColor="inherit"
              classes={{ indicator: styles.tabsHeader }}
            >
              <Tab
                value={0}
                label={
                  <span className={styles.detailTabLabel}>
                    {i18n.t("planta.inventario")}
                  </span>
                }
                id="inventory-tab-0"
              />
              <Tab
                value={1}
                label={
                  <span className={styles.detailTabLabel}>
                    {i18n.t("assessment.riskFactors")}
                  </span>
                }
                id="inventory-tab-1"
              />
              <Tab
                value={2}
                label={
                  <span className={styles.detailTabLabel}>
                    {i18n.t("planta.assessmentVersions")}
                  </span>
                }
                id="inventory-tab-2"
              />
            </Tabs>
          </Paper>
          {inventoryTabValue === 0 && (
            <InventarioTable
              plantaId={id}
              keyuser={keyuser}
              nombre={planta.denominacion}
              isGestorThisPartner={isGestorThisPartner}
              isGestorThisPlant={isGestor}
            />
          )}
          {inventoryTabValue === 1 && (
            <AssessmentTable plantaId={id} keyuser={keyuser} />
          )}
          {inventoryTabValue === 2 && (
            <SiteAssessmentVersionsTable {...props} plantaId={id} />
          )}
        </>
      )}
      {tabValue === 6 && (
        <PlantaStockEmergenciaTable
          plantaId={id}
          readOnly={readOnly}
          isPremium={isPremium}
        />
      )}
      {tabValue === 7 && (
        <PlantaLogs
          plantaId={id}
        />
      )}
      {/*@ts-ignore*/}
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("planta.alertDialog.title")}
        text={i18n.t("planta.alertDialog.text")}
        cancelText={i18n.t("planta.alertDialog.cancelText")}
        confirmText={i18n.t("planta.alertDialog.confirmText")}
        confirmAction={() => deletePlanta()}
      />
      <EventDialog
        {...props}
        open={eventDialogOpen}
        events={eventDialogEvents}
        onClose={() => setEventDialogOpen(false)}
        onItemClick={(event) => {
          const { equipoId, fisicalElementId } = event;
          if (equipoId) {
            window.open(`./plantas/${id}/equipos/${equipoId}`, "_blank");
          } else {
            window.open(
              `./plantas/${id}/fisicalElements/${fisicalElementId}`,
              "_blank"
            );
          }
        }}
      />
    </div>
  );
}
