import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Paper,
  Tab,
  Tabs,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormControl,
} from "@material-ui/core";
import {
  Delete,
  Room,
  Save,
  HomeWorkOutlined,
  HelpOutline,
  Cancel,
  ArrowDropDown,
} from "@material-ui/icons";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import AlertDialog from "../../../components/AlertDialog";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import ZonaSearchAndAdd from "../../organism/ZonaSearchAndAdd/ZonaSearchAndAdd";
import i18n from "../../../locales/i18n";
import { ZonasAPI, PlantasAPI } from "../../../services/API";
import FrequencyConvertersSearchEngine from "../../organism/FrequencyConverterSearchEngine/FrequencyConverterSearchEngine";
import {
  capitalizeFirstLetter,
  SnackbarSuccess,
  SnackbarError,
} from "../../../helpers/Common";
import MouseOverPopover from "../../MouseOverPopover";
import AuthService from "../../../services/AuthService";
import AppContext from "../../../contexts/AppContext";
import { Common_Style, DetailTabs_Style } from "../../../assets/css/Styles";
import DriveIcon from "../../atoms/driveIcon";

export default function ZonasDetails(props) {
  const { match, keyuser, location } = props;
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const id = match.params.id;
  const plantaId = match.params.plantaId;
  const isPremium = AuthService.plantPremium(plantaId);
  const isGestorFromPlant = AuthService.isGestorFromPlant(plantaId);
  const readOnly = props.readOnly && !keyuser;

  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();

  const [zona, setZona] = useState({
    descripcion: "",
    plantaZonaId: "",
    id: "",
    plantaId: "",
    isWarehouse: false,
    warehouseID: null,
    numEquipos: 0,
  });
  const [oldZone, setOldZone] = useState(zona);
  const [initialIsWarehouse, setInitialWarehouse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [allZones, setAllZones] = useState([]);
  const [errors, setErrors] = useState({});

  const deleteDialog = useRef(null);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    window["AltidVars"] = {
      ...window["AltidVars"],
      Tabs_ZonasDetails: newValue,
    };
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setZona((prevState) => ({ ...prevState, [name]: value }));
    const errorName = name ? capitalizeFirstLetter(name) : null;
    if (errors[errorName]) {
      setErrors((e) => ({ ...errors, [errorName]: undefined }));
    }
  };

  const deleteZona = async () => {
    setLoading(true);
    ZonasAPI.remove(id)
      .then((response) => {
        if (response.ok) {
          SnackbarSuccess(setGlobal, i18n.t("zona.snackbar.deleted.success"));
          return response.json();
        } else {
          if (response.status === 404) {
            goBack();
          }
          SnackbarError(setGlobal, i18n.t("zona.snackbar.deleted.error"));
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        props.history.goBack();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getZona = useCallback(async () => {
    ZonasAPI.get(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setZona({ ...data, plantaZonaId: data.plantaZonaId || "-1" });
        setOldZone({ ...data, plantaZonaId: data.plantaZonaId || "-1" });
        setInitialWarehouse(data.isWarehouse);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id]);

  const updateZona = async () => {
    setLoading(true);
    const zonaToUpdate = {
      ...zona,
      plantaZonaId: zona.plantaZonaId === "-1" ? null : zona.plantaZonaId,
    };
    ZonasAPI.update(zonaToUpdate)
      .then(async (response) => {
        if (response.ok) {
          SnackbarSuccess(setGlobal, i18n.t("zona.snackbar.update.success"));
          setOldZone(zona);
          if (zona.isWarehouse !== initialIsWarehouse) {
            goBack();
          }
        } else {
          SnackbarError(setGlobal, i18n.t("zona.snackbar.update.error"));
          if (response.status === 404) {
            goBack();
          } else {
            var json = await response.json();
            setErrors(json.errors);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getZonesOfThePlant = useCallback(async () => {
    ZonasAPI.getZonasAbove(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setAllZones(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id]);

  const goBack = () => {
    if (props.history.length > 1) {
      handleChangeTab({}, 0);
      props.history.goBack();
    } else {
      props.history.replace(`/plantas/${plantaId}`);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
    if (
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_ZonasDetails !== undefined
    ) {
      setTabValue(window["AltidVars"].Tabs_ZonasDetails);
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.tabZonaValue) {
      setTabValue(location.state.tabZonaValue);
    }
  }, [location.state]);

  useEffect(() => {
    if (zona.descripcion) {
      setGlobal((prev) => ({
        ...prev,
        pageTitle: i18n.t("zona.title") + " " + zona.descripcion,
      }));
    } else {
      // setNavBarTitle(i18n.t("zona.title"));
      setGlobal((prev) => ({ ...prev, pageTitle: i18n.t("zona.title") }));
    }
  }, [setGlobal, zona.descripcion]);

  useEffect(() => {
    setLoading(true);
    Promise.all([getZonesOfThePlant(), getZona()]).then(() =>
      setLoading(false)
    );
  }, [getZonesOfThePlant, getZona]);

  return (
    <div className={commonStyles.mainContainer}>
      <Paper square>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          centered
          variant="standard"
          classes={{ indicator: styles.tabsHeader }}
        >
          <Tab
            label={
              <span className={styles.detailTabLabel}>
                {i18n.t("zona.itemTitle")}
              </span>
            }
            icon={<Room />}
            id="simple-tab-0"
            value={0}
          />
          <Tab
            label={
              <span className={styles.detailTabLabel}>
                {i18n.t("equipo.tituloCard")}
              </span>
            }
            icon={<DriveIcon color={"#212121"} />}
            id="simple-tab-2"
            value={1}
          />
        </Tabs>
      </Paper>
      {tabValue === 0 && (
        <RelativeBackdrop hidden={!loading}>
          <div className={styles.mainPageContainer}>
            <Card>
              <div className={styles.mainCardFormContainer}>
                <div className={styles.formCardContainer}>
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    {zona.isWarehouse && (
                      <div className={styles.pillContainer}>
                        <div className={styles.flexPill}>
                          <span className={styles.pillText}>
                            {i18n.t("zona.warehouse")}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className={styles.flexRowForm}>
                      <CardHeader
                        title={zona.descripcion}
                        subheader={
                          zona.isWarehouse
                            ? i18n.t("zona.warehouse")
                            : i18n.t("zona.itemTitle")
                        }
                        avatar={
                          <Avatar
                            aria-label="avatar"
                            className={styles.tabHeaderAvatar}
                          >
                            {zona.isWarehouse ? <HomeWorkOutlined /> : <Room />}
                          </Avatar>
                        }
                      />
                    </div>
                    <div className={styles.flexRowForm}>
                      <div className={styles.flexCellForm}>
                        <TextField
                          required
                          fullWidth
                          className={styles.materialTextField}
                          value={zona.descripcion || ""}
                          label={i18n.t("zona.descripcion")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          error={errors["Descripcion"] !== undefined}
                          helperText={
                            errors["Descripcion"]
                              ? i18n.t("textField.required")
                              : null
                          }
                          name="descripcion"
                          onChange={handleInputChange}
                        />
                      </div>
                      {allZones.length ? (
                        <div className={styles.flexCellForm}>
                          <TextField
                            fullWidth
                            select
                            label={i18n.t("zona.location")}
                            value={zona.plantaZonaId || "-1"}
                            inputProps={{
                              readOnly: readOnly || !isGestorFromPlant,
                            }}
                            className={
                              readOnly || !isGestorFromPlant
                                ? styles.materialTextFieldSelectorReadOnly
                                : styles.materialTextField
                            }
                            name="plantaZonaId"
                            onChange={handleInputChange}
                            disabled={
                              zona.warehouseID && zona.warehouseID !== zona.id
                            }
                            SelectProps={{
                              IconComponent:
                                readOnly || !isGestorFromPlant
                                  ? () => null
                                  : ArrowDropDown,
                            }}
                          >
                            <MenuItem value="-1">
                              {i18n.t("zona.locationFirtLevel")}
                            </MenuItem>
                            {allZones.map((item) =>
                              item.id !== zona.id ? (
                                <MenuItem
                                  key={item.id.toString()}
                                  value={item.id}
                                >
                                  {item.descripcion}
                                </MenuItem>
                              ) : (
                                ""
                              )
                            )}
                          </TextField>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className={styles.flexRowForm}>
                      <div className={styles.flexCellForm}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              readOnly={readOnly}
                              checked={zona.isWarehouse}
                              onChange={handleInputChange}
                              name="isWarehouse"
                              className={styles.checkBoxForm}
                              disabled={
                                readOnly ||
                                (zona.warehouseID &&
                                  zona.warehouseID !== zona.id)
                              }
                            />
                          }
                          label={
                            <div>
                              <FormControl fullWidth>
                                <div style={{ marginRight: 20 }}>
                                  {i18n.t("zona.isWarehouse")}
                                </div>
                                <MouseOverPopover
                                  content={
                                    <>
                                      <b style={{ textTransform: "none" }}>
                                        {i18n.t("zona.infoWarehouse")}
                                      </b>
                                    </>
                                  }
                                  parent={
                                    <HelpOutline
                                      style={{ fontSize: 16 }}
                                      className={styles.outlineIcon}
                                    />
                                  }
                                />
                              </FormControl>
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <div className={styles.flexRowForm}>
                      <div className={styles.flexCellForm}>
                        {!readOnly && (
                          <>
                            <Button
                              variant="contained"
                              className={styles.primaryFormButton}
                              onClick={() => updateZona()}
                            >
                              <Save className={styles.saveIcon} />
                              {i18n.t("common.save")}
                            </Button>
                            <Button
                              variant="outlined"
                              className={styles.secondaryFormButton}
                              onClick={() => setZona(oldZone)}
                            >
                              <Cancel className={styles.saveIcon} />
                              {i18n.t("common.cancel")}
                            </Button>
                            <Button
                              variant="outlined"
                              className={styles.secondaryFormButton}
                              onClick={() => deleteDialog.current.open()}
                            >
                              <Delete className={styles.deleteIcon} />
                              {i18n.t("common.delete")}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Card>
            {id && (
              <ZonaSearchAndAdd
                plantaId={plantaId}
                zonaId={id}
                readOnly={readOnly}
                history={props.history}
                goBack={goBack}
              />
            )}
          </div>
        </RelativeBackdrop>
      )}
      {tabValue === 1 && (
        <FrequencyConvertersSearchEngine
          readOnly={readOnly}
          isPremium={isPremium}
          PlantaId={plantaId}
          ZonaId={id}
          history={props.history}
          goBack={goBack}
          setErrors={setErrors}
          nDrivers={zona.numEquipos}
        />
      )}
      {/* @ts-ignore */}
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("zona.delete")}
        text={i18n.t("planta.alertDialog.text")}
        cancelText={i18n.t("planta.alertDialog.cancelText")}
        confirmText={i18n.t("planta.alertDialog.confirmText")}
        confirmAction={() => deleteZona()}
      ></AlertDialog>
    </div>
  );
}
