import React, { useCallback, useEffect, useState } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import i18n from "i18n-js";
import MaterialTable from "material-table";
import { PlantasAPI } from "../../../services/API";

export default function PlantaLogs(props) {
  const { plantaId } = props;

  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  const materialTableTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#000000",
      },
    },
  });

  const getLogs = useCallback(() => {
    setLoading(true);
    PlantasAPI.getPlantLogs(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .then((data) => {
        setLogs(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [plantaId]);

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  const getValueFromData = (data, oldOrNew) => {
    switch (data.property) {
      case "CountryId":
        return i18n.t(`country.${data[oldOrNew]}`);
      case "infoBySalesforce":
        return data[oldOrNew] === "True"
          ? i18n.t("logs.afirmative")
          : i18n.t("logs.negative");
      default:
        return data[oldOrNew];
    }
  };

  return (
    <div>
      <div>
        <ThemeProvider theme={materialTableTheme}>
          {/* @ts-ignore */}
          <MaterialTable
            style={{ width: "100%" }}
            title={null}
            localization={i18n.t("dataTableProps")}
            isLoading={loading}
            data={logs}
            options={{
              sorting: true,
              showTitle: false,
              searchFieldAlignment: "left",
              pageSize: 10,
              actionsColumnIndex: -1,
              tableLayout: "fixed",
            }}
            columns={[
              {
                field: "changeDate",
                title: i18n.t("logs.changeDate"),
                type: "datetime",
              },
              {
                field: "userId",
                title: i18n.t("logs.user"),
                render: (rowData) =>
                  rowData.appUser
                    ? rowData.appUser.nombre +
                      " " +
                      rowData.appUser.apellidos +
                      " (" +
                      rowData.appUser.email +
                      ")"
                    : "",
              },
              {
                field: "property",
                title: i18n.t("logs.property"),
                render: (rowData) =>
                  i18n.t("logs." + rowData.property.toLowerCase()),
              },
              {
                field: "oldValue",
                title: i18n.t("logs.oldValue"),
                render: (row) => getValueFromData(row, "oldValue"),
              },
              {
                field: "newValue",
                title: i18n.t("logs.newValue"),
                render: (row) => getValueFromData(row, "newValue"),
              },
            ]}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}
